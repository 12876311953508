import {
  FETCH_TRAVELLEGS_FAILURE,
  FETCH_TRAVELLEGS_REQUEST,
  FETCH_TRAVELLEGS_SUCCESS,
  RESET_TRAVELLEGS,
  SET_SELECTED_TRAVELLEG
  } from '../actions/travelLegsActions';

const initialState = {
  loading: false,
  error: null,
  travellegs: null,
  selectTravelLeg: null
};

const travelLegsReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case FETCH_TRAVELLEGS_REQUEST:
        return { ...state, loading: true, error: null, travellegs: null, selectTravelLeg: null};
  
    case FETCH_TRAVELLEGS_SUCCESS:
      return {
        ...state,
        loading: false,
        travellegs: action.payload,
      };

    case RESET_TRAVELLEGS:
      return {
        ...state,
        travellegs: null,
      }

    case FETCH_TRAVELLEGS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case SET_SELECTED_TRAVELLEG:
      return {...state, selectTravelLeg: action.payload};
            
    default:
        return state;
  }
};

export default travelLegsReducer;
