import {
  CLEAR_ACTIVE_LAYERS,
  FETCH_LAYERS_FAILURE,
  FETCH_LAYERS_REQUEST,
  FETCH_LAYERS_SUCCESS,
  SET_ACTIVE_BASE_LAYERS,
  SET_ACTIVE_OTHER_LAYERS
} from '../actions/layersActions';

const initialState = {
  availableLayers: null,
  availableBaseLayers: null,
  availableOtherLayers: null,
  activeOtherLayers: null,
  activeBaseLayers: null
};

export default function layersReducer (state = initialState, action = null) {

  switch (action.type) {
    case CLEAR_ACTIVE_LAYERS:
        return { ...state, loading: true, error: null };

    case FETCH_LAYERS_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          availableBaseLayers: action.payload.availableBaseLayers,
          availableOtherLayers: action.payload.availableOtherLayers,
          availableLayers: action.payload.availableLayers,
          activeOtherLayers: action.payload.defaultActiveOtherLayers,
          activeBaseLayers: action.payload.defaultActiveBaseLayers,
        };
    case FETCH_LAYERS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case FETCH_LAYERS_REQUEST:
      return { ...state, loading: true, error: null };

    case SET_ACTIVE_BASE_LAYERS:
      return {
        ...state,
        activeBaseLayers: action.payload,
      };
    case SET_ACTIVE_OTHER_LAYERS:
        console.log("SET_ACTIVE_OTHER_LAYERS", state, action)
        return {
          ...state,
          activeOtherLayers: action.payload,
        };
      
    default:
        return state;
  }
};


