function wipeMap (mapRef) {
    console.log("~~ WIPEMAP ~~", mapRef)

    if (!mapRef) {
        return
    };

    mapRef.eachLayer((layer) => {
       if (!!layer.feature) {

            mapRef.removeLayer(layer)
        } else {

            //console.log("WIPEMAP layer.feature", layer.feature)
        }
    })

}

export { wipeMap }