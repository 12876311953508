import { useDispatch, useSelector } from 'react-redux';

import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css'; /* notably for tooltip arrows! */


import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import LayersIcon from '@mui/icons-material/Layers';

import { Box, Checkbox, FormControl, FormLabel, List, ListItem, ListItemButton, ListItemContent, Radio, RadioGroup } from '@mui/joy';
import { setActiveBaseLayers, setActiveOtherLayers } from '../actions/layersActions';
import { selectActiveOtherLayers, selectAvailableBaseLayers, selectAvailableOtherLayers, selectLayersLoading, selectMapSelect } from '../selectors/selector';

import { Loading } from "./Loading";
import Typography from '@mui/joy/Typography';



function Toggler({
  defaultExpanded = true,
  renderToggle,
  children,
}) {
  const [open, setOpen] = React.useState(defaultExpanded);
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={[
          {
            display: 'grid',
            transition: '0.2s ease',
            '& > *': {
              overflow: 'hidden',
            },
          },
          open ? { gridTemplateRows: '1fr' } : { gridTemplateRows: '0fr' },
        ]}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}


export const SIDEBAR_WIDTH = '500px';

export default function LayerSelection() {

  const dispatch = useDispatch();
  const mapSelect = useSelector(selectMapSelect);
  
  const availableBaseLayers = useSelector(selectAvailableBaseLayers) 
  const availableOtherLayers = useSelector(selectAvailableOtherLayers)

  const activeOtherLayers = useSelector(selectActiveOtherLayers)
  

  const layersLoading = useSelector(selectLayersLoading)



  const handleCheckboxChange = (event) => {

      const { value, checked } = event.target;

      let newList = [...activeOtherLayers];


      if (checked && !newList.includes(value)) {
        newList.push(value);
      }

      if (!checked && newList.includes(value)) {
        newList = newList.filter(existingLayerId => existingLayerId != value)
      }

      dispatch(setActiveOtherLayers(newList))
  }

  return <div
      className="contrast destination-container p-2"
      style={{
        position: 'absolute',
        width: SIDEBAR_WIDTH,
        display: 'flex',
        width: SIDEBAR_WIDTH,
        overflowY: 'scroll',
        overflowX: 'clip',
        flexDirection: 'column',
      }}
      id="layersSelectDiv"
    > 


{!availableBaseLayers ? null :
<List
    size="sm"
    sx={{
      gap: 1,
      '--List-nestedInsetStart': '30px',
      '--ListItem-radius': (theme) => theme.vars.radius.sm,
    }}>
          <ListItem nested>
           <Toggler
              defaultExpanded
              renderToggle={({ open, setOpen }) => (
                <ListItemButton onClick={() => setOpen(!open)}>
                  <LayersOutlinedIcon/>
                  <ListItemContent>
                    <Typography level="title-sm">Base layers</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={[
                      open ? { transform: 'rotate(180deg)' } : { transform: 'none' },
                    ]}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>

            <FormControl>
              <RadioGroup
                onChange={(event) => dispatch(setActiveBaseLayers([event.target.value]))} 
                name="radio-buttons-group"
                defaultValue="1">
                {Object.entries(availableBaseLayers).map(([layerId, layerInfo]) => { 
                    if (!layerInfo) return;

                    return <ListItem>
                      <Radio value={layerId} label={layerInfo?.layerLabel} variant="plain" defaultChecked={layerInfo?.isDefault}/>
                    </ListItem>
                })}
              </RadioGroup>
            </FormControl>

              </List>
            </Toggler>
          </ListItem>
        </List>
}




{ !availableOtherLayers ? null : 
  <List
    size="sm"
    sx={{
      gap: 1,
      '--List-nestedInsetStart': '30px',
      '--ListItem-radius': (theme) => theme.vars.radius.sm,
    }}>
          <ListItem nested>
           <Toggler
              defaultExpanded
              renderToggle={({ open, setOpen }) => (
                <ListItemButton onClick={() => setOpen(!open)}>
                  <LayersIcon/>
                  <ListItemContent>
                    <Typography level="title-sm">Other layers</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={[
                      open ? { transform: 'rotate(180deg)' } : { transform: 'none' },
                    ]}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>




        <div role="group" aria-labelledby="sandwich-group" onChange={handleCheckboxChange}>
      <List size="sm">

        {Object.entries(availableOtherLayers).map(([layerId, layerInfo]) => { 
            return <ListItem>
              <Checkbox
                value={layerId} 
                label={layerInfo?.layerLabel} 
                variant="plain" 
                defaultChecked={layerInfo?.isDefault}
              />
            </ListItem>
        })}
      </List>

    </div>



              </List>
            </Toggler>
          </ListItem>
        </List>
}



    </div>
    
}

