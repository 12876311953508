import { SET_MAX_DURATION } from '../actions/durationActions';
  
  const initialState = {
    maxDuration: 60,
  };
  
  const durationReducer = (state = initialState, action = null) => {
    switch (action.type) {
  
      case SET_MAX_DURATION:
          return { ...state, maxDuration: action.payload};
            
  
      default:
          return state;
    }
  };
  
  export default durationReducer;
  