function minutesToTimeStr(minutes) {
  const hour = Math.floor(minutes / 60);
  const minute = minutes % 60;
  const hourStr = hour === 0 ? '' : `${hour}h`;
  const minuteStr = `${minute}m`;

  return `${hourStr} ${minuteStr}`;
}


function getURL(endpoint) {
  const domain = process.env.NODE_ENV === "development" ? "http://localhost:5000" : "/api"
//  const domain =  "http://localhost:5000" //process.env.NODE_ENV === "development" ? "http://localhost:5000" : "/api"


  return `${domain}/${endpoint}`
}

export {minutesToTimeStr, getURL};
