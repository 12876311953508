import './styles/App.css'; 

import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'; /* notably for tooltip arrows! */

import { Autocomplete } from './components/Autocomplete';
import CategoryList from "./components/CategoryList";
import DurationSelect from './components/DurationSelect';
import DestinationOverlay  from "./components/DestinationOverlay"
import DestinationList from "./components/DestinationList"
import LeafletMap from "./map/LeafletMap";

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import BrightnessAutoRoundedIcon from '@mui/icons-material/BrightnessAutoRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import LayersIcon from '@mui/icons-material/Layers';


import { useDispatch, useSelector } from 'react-redux';
import { fetchStops } from './actions/stopsActions';
import Header from './components/Header';
import { fetchMaps, setMapSelect } from './actions/mapSelectActions';
import { fetchTravelLegs } from './actions/travelLegsActions';
import { selectFocusRawMapData, selectHasDestinationsMap, selectMapSelect, selectSelectArrId, selectSelectedStartStop } from './selectors/selector';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import LayerSelection from './components/LayerSelection';

//import Stack from 'react-bootstrap/Stack';
import Stack from '@mui/joy/Stack';
import { Box, Chip, Divider, List, ListItem, ListItemButton, ListItemContent, Typography } from '@mui/joy';
import { fetchLayers } from './actions/layersActions';
const DEFAULTMAP = "ch_winter"


const SIDEBAR_WIDTH = "500px"


const ObjectList = ({ data, excludeKeys = [] }) => {
  const renderList = (obj) => {
    return !obj ? null : (
      <ul>
        {Object.keys(obj)
          .filter((key) => !excludeKeys.includes(key))
          .map((key, index) => (
            <li key={index}>
              <strong>{key}: </strong>
              {typeof obj[key] === 'object' ? (
                renderList(obj[key])
              ) : (
                obj[key]
              )}
            </li>
          ))}
      </ul>
    );
  };

  return !data ? null : renderList(data);
};

function App() {

  const dispatch = useDispatch();
  const stopList = useSelector((state) => state.stops.list);
  const maps = useSelector((state) => state.maps.maps);
  const selectArrId = useSelector(selectSelectArrId);
  const selectedStartStop = useSelector(selectSelectedStartStop);
  const focusRawMapData = useSelector(selectFocusRawMapData);
  const hasDestinationsMap = useSelector(selectHasDestinationsMap);
  const mapSelect = useSelector(selectMapSelect);
  
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!!stopList) return;
    dispatch(fetchStops());
  }, []);

  useEffect(() => {
    if (!!maps) return;
    dispatch(fetchMaps());
  }, []);


  useEffect(function loadLayers() { 
    if (!mapSelect) {
        return;
      }
      dispatch(fetchLayers(mapSelect));
  }, [mapSelect])


  // On initial load, set map from url
  useEffect(() => {
    const mapFromURL = location.pathname.split('/')[1]; // Extract map1/map2 from /map1

    dispatch(setMapSelect(mapFromURL ? mapFromURL : DEFAULTMAP)); 

  }, [location.pathname, dispatch]);

  
  useEffect(() => {
    if (!!mapSelect) {
      /* Update URL while preserving existing parameters */
      const currentParams = Object.fromEntries(searchParams.entries());
      
      navigate(`/${mapSelect}?${new URLSearchParams(currentParams).toString()}`, { replace: true });  
    }
  }, [mapSelect, navigate]);


  useEffect(function fetchOnChange() {
    console.log("APPBASE selectArrId",selectedStartStop, selectArrId)
    
    if (!selectedStartStop || !selectArrId) return;

    dispatch(fetchTravelLegs(selectedStartStop, selectArrId.value));

  }, [dispatch, selectedStartStop, selectArrId])


  return <div className="App">

    <Header/>

    <div style={{position: "absolute",
                width: `calc(100% - ${SIDEBAR_WIDTH})`,
                height: "calc(100% - 30px)",
                top: "30px",
                right: "0"}}>
        <LeafletMap/>
    </div>

  <div className="sidepane" style={{
        position: "absolute", 
        width: `calc(${SIDEBAR_WIDTH})`,
        height: "calc(100% - 30px)",
        top: "30px",
        left: "0",
        padding: "10px 10px"
    }}>
    
  <Stack sx={{ height: "100%", width: "100%", overflowY : 'clip' }}
      direction="column"
      spacing={2}
      divider={<Divider orientation="horizontal" sx={{border: "1px gray solid"}}/>}
    >
    <Stack spacing={3} sx={{width: "100%"}}>


    {!hasDestinationsMap ? null : <CategoryList/>}      
    <Autocomplete />
    <DurationSelect/>

    </Stack>

    <div style={{
      overflow: "clip",
      width: "calc(100%)",
      height:"calc(100%)",
    }}>
    {!hasDestinationsMap ? null : <DestinationList/>}
    {!!hasDestinationsMap ? null : <LayerSelection/>}

    </div>


        </Stack>


  </div>

  <DestinationOverlay/>

  </div>
}

export default App;
//</div> <div>
//</div>  <Stack className="sidebar p-3 " gap={3}
//</div>        style={{width: SIDEBAR_WIDTH,
//</div>        //height: "200px",
//</div>        backgroundColor: "#eee",
//</div>        display: "flex",
//</div>        flexDirection: "column",
//</div>        borderBottom: '1px darkgray solid',
//</div>
//</div>      }}>
//</div>
//</div>      {!hasDestinationsMap ? null : <CategoryList/>}
//</div>      <Autocomplete />
//</div>      <DurationSelect/>
//</div>
//</div>     {/*  <div style={{
//</div>        position: "absolute", fontSize: "x-small", right: "0px",
//</div>        maxHeight: "calc(100% - 36px)", overflowY: "scroll",
//</div>        bottom: "0px", zIndex: 1000000000, 
//</div>                  backgroundColor: "white"}}>
//</div>      <ObjectList data={focusRawMapData}/>
//</div>      <ObjectList data={{map: mapSelect}}/>
//</div>      </div> */}
//</div>
//</div>  </Stack>
//</div>
//</div>
//</div>  {!hasDestinationsMap ? null : <DestinationList/>}
//</div>
//</div>  {!!hasDestinationsMap ? null : <LayerSelection/>}
//</div>
//</div>{/*   <StopCard
//</div>    destinationId={null}
//</div>    startStopName={"aa"} 
//</div>    duration={10}
//</div>    destinationContent={"aa"}
//</div>    onDestinationCardClick={() => {}}
//</div>
//</div>  /> */}
//</div>  </div>
