import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import "leaflet-canvas-marker";

import { redIcon } from "./markers";

import * as L from 'leaflet';

import 'leaflet.markercluster';
import { useDispatch, useSelector } from "react-redux";
import { setFocusDestinationId } from "../actions/destinationActions";
import { setSelectedArrStopId } from "../actions/stopsActions";
import { fetchTravelLegs } from "../actions/travelLegsActions";
import { selectFilteredRawMapData, selectMapSelect } from "../selectors/selector";
  
export default function LeafletMarkerCluster({
        durationMapData,
        focusDestinationId, selectedStartStop,
        selectCategory, maxDuration, mapSelect
      }) {

    const dispatch = useDispatch();

    const map = useMap();
    const [markerClusterGroup, setMarkerClusterGroup] = useState(null);
    const filteredRawMapData = useSelector(selectFilteredRawMapData);

    const handleDestinationMarkerClick = (id) => {

      dispatch(setFocusDestinationId({value: Number(id), source: "map"}));

      if (!durationMapData) return;

      const arrStopId = !durationMapData ? null : durationMapData[id]?.stopIdClosest;
      dispatch(setSelectedArrStopId({value: arrStopId, source: "map"}))
      dispatch(fetchTravelLegs(selectedStartStop, arrStopId))
    }

    function populateMarkerClusterGroup() {

      if (!markerClusterGroup || !map || !filteredRawMapData) {

        return;
      }      
      

      Object.entries(filteredRawMapData).forEach(([idDestination, feature]) => {


        if (focusDestinationId?.value === idDestination) {
          // focus destination is not plotted as part of cluster, but separately!!
          return
        }

        const marker = L.marker([feature.lat, feature.lon], { icon: redIcon })
           .bindTooltip(`${feature.destination_name}`, {direction: "top"})
           .on('click', () => { handleDestinationMarkerClick(idDestination) });

        //if (focusDestinationId !== feature.id_destination) {
        markerClusterGroup.addLayer(marker);

    });
  }


    function clearMarkerClusterGroup() {
        markerClusterGroup.clearLayers();
    }

    useEffect(function initMarkerClusterGroup() {

        if (!!markerClusterGroup || !map) {
            return
        };

        const newMarkerClusterGroup = L.markerClusterGroup({
              chunkedLoading: true,
              showCoverageOnHover: false,
              spiderfyOnMaxZoom: true,
              //animateAddingMarkers: true
        });

        setMarkerClusterGroup(newMarkerClusterGroup)
        map.addLayer(newMarkerClusterGroup)

    }, [map, filteredRawMapData, markerClusterGroup])

    useEffect(function resetMarkerClusterGroup() {

      console.log("CALLED resetMarkerClusterGroup!")

      if (!markerClusterGroup | !map | !filteredRawMapData) {
          return
        };

        clearMarkerClusterGroup()
        populateMarkerClusterGroup()

    }, [
      mapSelect,
      markerClusterGroup,
      filteredRawMapData,
      durationMapData,
      focusDestinationId,
      maxDuration,
      selectCategory,
      selectedStartStop
    ])

    return null;
}
