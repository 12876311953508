import { useEffect, useState } from "react";
import { useMap, useMapEvent } from "react-leaflet";
import "leaflet-canvas-marker";
import L, { marker, canvasIconLayer } from "leaflet";

import '../styles/map.css'; 
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedStartStop } from "../selectors/selector";
import { getURL } from "../utils/utils";
import { fetchTravelLegs } from "../actions/travelLegsActions";
import { wipeMap } from "../utils/mapUtils";

// import eurovelo6GeoJSON from "../static/eurovelo6_gzip.json"



const neighborColor = "#6E1511";  //style.getPropertyValue('--heat-color');
const backgroundColor = "gray";  //style.getPropertyValue('--background-color');
const selectTripColor = "#E86F68";  //style.getPropertyValue('--select-trip-color');
const selectTripColorHighlight = "#48BCFA";  //style.getPropertyValue('--highlight-trip-color');
const stopsColor = "red";  //style.getPropertyValue('--stops-color');

function mapZoomToRadius(zoomLevel) {
    if (zoomLevel <= 8) {
      return 2;
    } 
    if (zoomLevel <= 10) {
      return 3
    } 
    if (zoomLevel <= 13) {
      return 4
    }
    if (zoomLevel <= 14) {
      return 4
    }
    return 4
  }
  
function getStopsStyle(type, radius) {

    switch (type) {
      case "neighbors": return {
        radius: radius,
        weight: 0,
        fillColor: neighborColor,
        fillOpacity: 1,
        color: neighborColor,
        interactive: true,
        zIndex: 3000
      };

      case "hidden": return {
        weight: 0,
        color: "#0000",
        fillColor: "#0000",
        interactive: false,
        zIndex: 3000
      };
      case "default": return {
        radius: radius,
        weight: radius < 3 ? 0 : 2,
        fillColor: radius < 3 ? "darkred" : "red",// neighborColor,
        fillOpacity: 1,
        color: "black",
        interactive: true,
        zIndex: 3000
      };
    };
  }


export default function LeafletCanvasMarker({maxDuration}) {

  const dispatch = useDispatch();

  const [zoomLevel, setZoomLevel] = useState(null);
  const [layer, setLayer] = useState(null);  

  const map = useMap();
  const stopList = useSelector((state) => state.stops.list)
  const isochroneData = useSelector((state) => state.isochrone.data)
  const selectedStartStop = useSelector(selectSelectedStartStop);
  const mapSelect = useSelector((state) => state.maps.mapSelect)


  useEffect(() => {
    console.log("LEAFLETCANVASMARKER changed selectedStartStop", selectedStartStop)


  }, 
  [selectedStartStop])

  const handleStopClick = (destinationStopName) => {
    dispatch(fetchTravelLegs(selectedStartStop, destinationStopName))
  }

  const mapEvents = useMapEvent({
    zoomend: () => {
        setZoomLevel(mapEvents.getZoom());
    },
  });

  useEffect(() => {

    if (!layer) {
      return;
    };

    const resetAll = !isochroneData

    const radius = mapZoomToRadius(zoomLevel);

    console.log("LEAFLETCANVASMARKER STARTING ==============================================================================")
    console.log("LEAFLETCANVASMARKER STARTING ISOCHRONE LAYER UPDATE...", zoomLevel)


    const hiddenStyle = getStopsStyle("hidden", null);
    const defaultStyle = getStopsStyle("default", radius);
    
    layer.eachLayer(function(thisLayer) {
      /* Requires isochroneData list {"Stop Name": {"distance": value}} */
      const thisStopName = thisLayer.feature.properties.stopName;
      
      const stopNotReachable = !isochroneData?.hasOwnProperty(thisStopName);
      const stopTooFar = stopNotReachable ? false : isochroneData[thisStopName].distance > maxDuration * 60 ;
      
      const newStyle = (stopNotReachable | stopTooFar) & !resetAll ? hiddenStyle :  defaultStyle
      
      
      if (thisStopName === "Lausanne") {
        console.log(
            "LEAFLETCANVASMARKER IresetAllSOCHRONE LAYER UPDATE SELECT STOP", `resetAll=${resetAll}`, 
            thisLayer, thisStopName, stopNotReachable, stopTooFar, newStyle)
      }

      thisLayer.setStyle(newStyle);

      thisLayer.off("click")
      thisLayer.on('click', () => {
        console.log("clicked ", thisStopName);
        dispatch(fetchTravelLegs(selectedStartStop, thisStopName));
    });

    })

  }, [isochroneData, maxDuration, selectedStartStop, zoomLevel])


  useEffect(() => {
    console.log("LEAFLETCANVASMARKER INIT GEOJSON");

    wipeMap(map)

    if (!map | !stopList) return;

    if (!!layer) {
      console.log("LEAFLETCANVASMARKER INIT GEOJSON => layer exists, aborting");
      return;
    }

    var geojsonFeature = {
        "type": "FeatureCollection",
        "features": Object.values(stopList).map(({lat, lon, name}) => {
          return {
            "type": "Feature",
            "properties": {"stopName": name},
            "geometry": {
              "coordinates": [
                lon,
                lat
              ],
              "type": "Point"
            }
          }
      })};

    const newRadius = mapZoomToRadius(map.getZoom());
    const defaultStyle = getStopsStyle("default", newRadius);  // newRadius);

    const newLayer = new L.geoJson(null, {
        
        style: {renderer: L.canvas({tolerance: 10})},

        pointToLayer: function (feature, latlng) {

          const c = L.circleMarker(latlng, defaultStyle);

          c.on('mouseover', () => {
              c.bindPopup(feature.properties.stopName, {className: 'mouseover-popup'});
              c.openPopup();
          });
          
          c.on('mouseout', () => c.closePopup());
            
        return c},
      });
      
      newLayer.addData(geojsonFeature);
      map.addLayer(newLayer);

      setLayer(newLayer);

      /* fetch(getURL('static/eurovelo6.json'))
      .then(response => response.json())
      .then(data => {
          var geojsonLayer = L.geoJSON(data);
          geojsonLayer.addTo(map);
      })
      .catch(error => {
          console.error('Error reading JSON file:', error);
      }); */

  }, [map, stopList, mapSelect]);

  return null;
}