import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuDrawer from './MenuDrawer';
import { useSelector } from 'react-redux';
import { selectMaps } from '../selectors/selector';
//import ColorSchemeToggle from './ColorSchemeToggle';


const styles = {
  customizeToolbar: {
    minHeight: 36
  }
};

export default function Header() {

  const mapSelect = useSelector((state) => state.maps.mapSelect)
  const maps = useSelector(selectMaps)

  return <Box sx={{ flexGrow: 1, alignContent: "space-between", zIndex: 100000}} >
    <AppBar sx={{ minHeight: "30px!important", maxHeight: '30px'}} position="static">
      <Toolbar sx={{ minHeight: "30px!important"}} className={styles.customizeToolbar} >        
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {
              !maps ? null : Object.values(maps).filter((x) => x.map_name === mapSelect)[0].map_label
            }
          </Typography>
         {/*  <ColorSchemeToggle sx={{ ml: 'auto' }} /> */}
        <MenuDrawer 
            mapSelect={mapSelect} 
            mapList={maps}
            color="inherit"/>
      </Toolbar>
    </AppBar>
  </Box>
}