import '../styles/map.css'; 

import { useEffect, useState } from 'react';
import { MapContainer } from 'react-leaflet/MapContainer'
import { Pane } from 'react-leaflet/Pane'
import MapCutout from "./MapCutout"
import FocusMarker from "./FocusMarker"
import { useMapEvent, useMapEvents } from "react-leaflet";
import LeafletMarkerCluster from "./LeafletMarkerCluster"
import { TransferRoute } from './TransferRoute';

import { useDispatch, useSelector } from 'react-redux';
import { fetchMapDestinations } from '../actions/destinationActions';
import { fetchStopTypes } from '../actions/stopsActions';
import { selectActiveBaseLayers, selectActiveLayers, selectActiveOtherLayers, selectAvailableBaseLayers, selectAvailableLayers, selectAvailableOtherLayers, selectBaseLayers, selectDestinationsDurationMapLoading, selectDurationMapData, selectFilteredRawMapData, selectFocusDestinationId, selectFocusRawMapData, selectHasCategoryStopTypesMap, selectHasDestinationsMap, selectMapSelect, selectMaps, selectMaxDuration, selectOtherLayers, selectSelectCategory, selectSelectedStartStop, selectSelectedStartStopData, selectStopList, selectStopTypesMap, selectTravelLegsData } from '../selectors/selector';
import LeafletCanvasMarker from './LeafletCanvasMarker';
import MarkerStartStop from './MarkerStartStop';
import { wipeMap } from '../utils/mapUtils';
import { useSearchParams } from 'react-router-dom';
import { map } from 'leaflet';
import { Button, ButtonGroup } from '@mui/joy';
import SingleTileLayer from './SingleTileLayer';
import { layer } from '@fortawesome/fontawesome-svg-core';
import { setActiveOtherLayers } from '../actions/layersActions';
import { MapLoading } from '../components/Loading';
import { GridLoader } from 'react-spinners';
import * as L from 'leaflet'


function ZoomControl() {

    const [zoomLevel, setZoomLevel] = useState(null); // initial zoom level provided for MapContainer
    const mapEvents = useMapEvents({
        zoomend: () => {
            setZoomLevel(mapEvents.getZoom());
        },
    });

    return null
}


function URLSync() {

    const [searchParams, setSearchParams] = useSearchParams();

    const updateURL = (center, zoom) => {
        const newParams = `z=${zoom}&x=${center.lat.toFixed(6)}/${center.lng.toFixed(6)}`;
        setSearchParams(new URLSearchParams({x: center.lng.toFixed(4), y: center.lat.toFixed(4), z: zoom}));
    };

    const map = useMapEvent({
        moveend: () => {
            const center = map.getCenter();
            const zoom = map.getZoom();
            updateURL(center, zoom)
        },
      });

    return null
}


function LeafletMap () {

    const [searchParams, setSearchParams] = useSearchParams();
    const [isParamsSet, setIsParamsSet] = useState(false); // State to track if params are set

    const filteredRawMapData = useSelector(selectFilteredRawMapData);
    const mapSelect = useSelector(selectMapSelect);
    const maps = useSelector(selectMaps);
    const stopTypesMap = useSelector(selectStopTypesMap);
    const focusDestinationId = useSelector(selectFocusDestinationId);
    const durationMapData = useSelector(selectDurationMapData);
    const selectedStartStop = useSelector(selectSelectedStartStop);
    const selectCategory = useSelector(selectSelectCategory);
    const maxDuration = useSelector(selectMaxDuration);
    const travelLegsData = useSelector(selectTravelLegsData);
    const stopList = useSelector(selectStopList)
    const hasCategoryStopTypesMap = useSelector(selectHasCategoryStopTypesMap)
    const hasDestinationsMap = useSelector(selectHasDestinationsMap)
    const availableLayers = useSelector(selectAvailableLayers);
    const activeBaseLayers = useSelector(selectActiveBaseLayers);

    const destinationsDurationMapLoading = useSelector(selectDestinationsDurationMapLoading)

    const selectedStartStopData = useSelector(selectSelectedStartStopData)
    const focusRawMapData = useSelector(selectFocusRawMapData);
    
    const dispatch = useDispatch();
    
    const [ mapRef, setMapRef ] = useState(null);
    const [ otherLayersGroup, setOtherLayersGroup ] = useState(null);
    
    const [mapSetFromParams, setMapSetFromParams] = useState(false); // Tracks when the map is ready

    useEffect(function initRawDestinationMapData() {

        if (!mapSelect) return;

        dispatch(fetchMapDestinations(mapSelect))

    }, [mapSelect, maps])

    useEffect(() => {
        if (!!stopTypesMap || !mapSelect) return;

        dispatch(fetchStopTypes(mapSelect));
      }, [mapSelect]);


    useEffect(() => {
        /* Note: Non-destinationsmaps call wipe map when populating the layers. */
        console.log("CALLING wipeMap")

        if (!hasDestinationsMap) return;    

        wipeMap(mapRef);

    }, [mapSelect])


//      useEffect(() => {
//  
//          console.log("LEAFLETMAP ACTIVEOTHERLAYERS ", )
//  
//          if (!mapRef || !activeOtherLayers) return;
//  
//          const newOtherLayersGroup = L.layerGroup().addTo(mapRef);
//  
//          activeOtherLayers.forEach((layerId) => {
//              const layerInfo = availableLayers[layerId];
//              const layer = L.tileLayer(layerInfo.url);
//              layer.setZIndex(2000)
//              newOtherLayersGroup.addLayer(layer);
//          });
//  
//          setOtherLayersGroup(newOtherLayersGroup);
//  
//  /*         return () => {
//              console.log("LEAFLETMAP ACTIVEOTHERLAYERS CLEANUP CALLED mapRef.removeLayer(newOtherLayersGroup);")
//              if (newOtherLayersGroup) {
//                  mapRef.removeLayer(newOtherLayersGroup);
//              }
//          };
//   */
//          return () => {
//              console.log("LEAFLETMAP ACTIVEOTHERLAYERS CLEANUP CALLED", mapRef)
//              // Remove all layers
//              mapRef?.eachLayer((layer) => {
//                if ("_url" in layer) {  /*Important: Not our own marker layers! */
//                   map.removeLayer(layer);
//                }
//              });
//            };
//        
//  
//  
//      }, [activeOtherLayers, mapSelect]);
    

/* 
    useEffect(function removeOtherLayersOnMapChange() {
        console.log("LEAFLETMAP CLEANUP removeOtherLayersOnMapChange", mapSelect)

        if (!mapRef || !otherLayersGroup) return;


        mapRef.removeLayer(otherLayersGroup)

    }, [mapSelect]);
     */



    useEffect(function flyOnDestinationFocusChange() {

        console.log("flyOnDestinationFocusChange")

        if (focusDestinationId?.source === "map") return;
        if (!focusRawMapData) return;

        const {lat, lon} = focusRawMapData;

        console.log("LEAFLETMAP flyOnDestinationFocusChange", lat, lon, focusRawMapData)
        if (!lat | lat === 0) return;
        mapRef?.flyTo([lat, lon], 13);

    }, [focusDestinationId])


    useEffect(function setMapFromParams () {
        
        if (!mapRef) return;
        
        /* Don't re-adjust after initial load */
        if (!!mapSetFromParams) return; 
        
        const lngFromURL = parseFloat(searchParams.get('x'));
        const latFromURL = parseFloat(searchParams.get('y'));
        const zoomFromURL = parseFloat(searchParams.get('z'));

        if (!lngFromURL | !latFromURL | !zoomFromURL) return;
            
        const zoom = parseFloat(zoomFromURL, 10);
        const center = [parseFloat(latFromURL), parseFloat(lngFromURL)];
    
        // Set the map view to match the URL parameters
        mapRef.setView(center, zoom);
    
        setMapSetFromParams(true);

    }, [searchParams, setSearchParams, mapRef]);
    
    useEffect(() => {

        console.log("LEAFLETMAP mapSelect CHANGED", mapSelect)
    }, [mapSelect])


    return <>
            {
                !!destinationsDurationMapLoading ?
                    <MapLoading/>
                : null
            }

           <MapContainer style={{position: "absolute", height: "100%", width: "100%"}}
                         minZoom={5}
                         zoom={8}
                         attributionControl={false}
                         bounds={[[47.721, 6.515], [45.886, 10.020]]}
                         maxBounds={[[45.398450,5.295410],[48.385442,11.162109]]}
                         maxBoundsViscosity={0.9}
                         ref={setMapRef}
                         className='custom-popup'
                         zoomSnap={0.25}  // better Switzerland fit
                         scrollWheelZoom={true} maxZoom={18}>

            <ZoomControl/>
            <URLSync/>

            <LeafletMarkerCluster
                    focusDestinationId={focusDestinationId}
                    selectedStartStop={selectedStartStop}
                    rawMapData={filteredRawMapData}
                    durationMapData={durationMapData}
                    selectCategory={selectCategory}
                    maxDuration={maxDuration}
                    mapSelect={mapSelect}/>

            {!focusDestinationId ? null : 
                <FocusMarker
                    selectCategory={selectCategory}
                    stopTypesMap={stopTypesMap}
                    categoryStopTypes={hasCategoryStopTypesMap}
                    selectedStartStop={selectedStartStop}
                    focusRawMapData={focusRawMapData} 
                    stopList={stopList}
                    focusDestinationId={focusDestinationId}
                    durationMapData={durationMapData}/>
            }

            {!travelLegsData ? null : <TransferRoute travelLegsData={travelLegsData} stopList={stopList} />}


            <MapCutout/>
            {/* {!hasDestinationsMap ? null : <MapCutout/> } */}


            {/* {!mapLoading ? null: <MapLoading/>} */}

            {
                !selectedStartStopData ? null : <MarkerStartStop lat={selectedStartStopData.lat} lon={selectedStartStopData.lon} stopName={selectedStartStopData.name}/>
            }

            {!!hasDestinationsMap ? null : <LeafletCanvasMarker maxDuration={maxDuration}/> }

{/*
            {(!!hasDestinationsMap || !activeOtherLayers) ? null :
                activeOtherLayers?.map((layerId) => {

                const layerInfo = availableLayers[layerId];
                console.log("LEAFLETMAP ADDING", layerInfo.layerName)
                return <Pane name={layerInfo.layerName} style={{"zIndex": 10000}}>
                    <SingleTileLayer key={`tilelayer-${layerId}`} url={layerInfo.url} zIndex={2000}/>
                    </Pane>
                })}
*/}

            <Button style={{zIndex: 100000000000 }} onClick={() => "a"}>
                WIPE MAP {mapSelect}
            </Button>

            {(!!hasDestinationsMap || !activeBaseLayers) ? null : 
                activeBaseLayers?.map((layerId) => {
                console.log("ADDING LAYERID ", layerId)
                const layerInfo = availableLayers[layerId];

                return <Pane name={layerInfo.layerName} style={{"zIndex": 1}}>
                    <SingleTileLayer key={`tilelayer-${layerId}`} url={layerInfo.url} zIndex={1000}/>
                    </Pane>
                })}


            </MapContainer>
        </>
}


export default LeafletMap;