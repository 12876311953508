import { combineReducers } from 'redux';
import destinationReducer from './destinationReducer';
import categoryReducer from './categoryReducer';
import durationReducer from './durationReducer';
import stopsReducer from './stopsReducer';
import travelLegsReducer from './travelLegsReducer';
import mapsReducer from './mapSelectReducer';
import isochroneReducer from './isochroneReducer';
import layersReducer from './layersReducer';

const rootReducer = combineReducers({
  destinations: destinationReducer,
  categories: categoryReducer,
  duration: durationReducer,
  stops: stopsReducer,
  travellegs: travelLegsReducer,
  maps: mapsReducer,
  isochrone: isochroneReducer,
  layers: layersReducer,
});

export default rootReducer;
