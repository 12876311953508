import React from 'react';

// Add the following lines in your index.js file before the App.js file import: 
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";

import ReactDOM from 'react-dom/client';
import './styles/index.css';
import './styles/scrollbar.css';
import App from './App';
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

const queryClient = new QueryClient()

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
      <BrowserRouter>
      <App />
      </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
