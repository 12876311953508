import axios from 'axios';
import { getURL } from '../utils/utils';

export const SET_ACTIVE_BASE_LAYERS = 'SET_ACTIVE_BASE_LAYERS';
export const SET_ACTIVE_OTHER_LAYERS = 'SET_ACTIVE_OTHER_LAYERS';
export const CLEAR_ACTIVE_LAYERS = 'CLEAR_ACTIVE_LAYERS';

export const FETCH_LAYERS_REQUEST = 'FETCH_LAYERS_REQUEST';
export const FETCH_LAYERS_SUCCESS = 'FETCH_LAYERS_SUCCESS';
export const FETCH_LAYERS_FAILURE = 'FETCH_LAYERS_FAILURE';


export const setActiveBaseLayers = (layerId) => ({
  type: SET_ACTIVE_BASE_LAYERS,
  payload: layerId,
});

export const setActiveOtherLayers = (layerIdList) => ({
  type: SET_ACTIVE_OTHER_LAYERS,
  payload: layerIdList,
});
 

export const clearActiveTiles = () => ({
  type: CLEAR_ACTIVE_LAYERS,
  payload: null,
});


export const fetchLayersRequest = () => ({
  type: FETCH_LAYERS_REQUEST,
});


export const fetchLayersSuccess = (availableLayers) => {

  const availableBaseLayers = Object.fromEntries(
    Object.entries(availableLayers).filter(([_, layerInfo]) => layerInfo?.isBaselayer === true)
  );

  const availableOtherLayers = Object.fromEntries(
    Object.entries(availableLayers).filter(([_, layerInfo]) => !layerInfo?.isBaselayer)
  );

  const defaultActiveOtherLayers = Object.entries(availableOtherLayers).filter(([_, layerInfo]) => !!layerInfo?.isDefault).map(([layerId, _]) => layerId);
  const defaultActiveBaseLayers = Object.entries(availableBaseLayers).filter(([_, layerInfo]) => !!layerInfo?.isDefault).map(([layerId, _]) => layerId);

  console.log("LAYERSACTION ", availableBaseLayers, availableOtherLayers,
    defaultActiveBaseLayers, defaultActiveOtherLayers
  )

  return { type: FETCH_LAYERS_SUCCESS,
    payload:  { availableBaseLayers, availableOtherLayers, availableLayers, defaultActiveOtherLayers, defaultActiveBaseLayers} };
};


export const fetchLayersFailure = (error) => ({
  type: FETCH_LAYERS_FAILURE,
  payload: error,
});

 
export const fetchLayers = (mapSelect) => {
  return async (dispatch) => {
    dispatch(fetchLayersRequest());
    await axios
      .get(getURL(`layers/${mapSelect}?`))
      .then((response) => {
        dispatch(fetchLayersSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchLayersFailure(error.message));
      });
  }

}