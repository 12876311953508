import { PropagateLoader, GridLoader } from "react-spinners"

/* https://dev.to/franklin030601/creating-an-infinite-scroll-with-react-js-4in5 */

export const MapLoading = ({ text, Loader=GridLoader, color="LightGray"}) => {
    

    return (
            <div style={{position: "absolute", width: "100%", height: "100%", 
                         backgroundColor:"gray", opacity: "0.6", zIndex: 1000000,
                         pointerEvents: "all", //transition: "opacity 0.5s"
                        }}>
                        <div style={{position: "absolute", left: "50%", top: "50%", 
                                     //backgroundColor: "red", width: "50px", height: "50px",
                                     transform: "translate(-50%, -50%)", display:"flex",
                                     flexDirection: "column", justifyContent: "center",
                                     alignItems: "center"}}><h5 style={{color: color, fontWeight: "bold"}}>Travelling...</h5>
                    <GridLoader size="30px" color={color} />
                  </div>
            </div>
    )
}

export const Loading = ({color="DarkGray", info=null}) => {

return (
    <div style={{//width: "100%", 
        height: "40px",
        justifyContent: "center",
        alignItems: "center",
         display: "flex", flexDirection: "column"
       }}>
        <PropagateLoader 
        cssOverride={{transform: "translateY(-8px)"}} color={color} />
        {info}
        </div>
)
}