import { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet-canvas-marker";
import { useLeafletContext } from '@react-leaflet/core'
import '../styles/map.css'; 
import { useSelector } from "react-redux";


export default function SingleTileLayer({ url, zIndex}) {
  const context = useLeafletContext()
  const map = useMap();
  const mapSelect = useSelector((state) => state.maps.mapSelect)

  useEffect(() => {

    const container = context.layerContainer || context.map

    const tileLayer = new L.TileLayer(url);

    tileLayer.setZIndex(zIndex)
    container.addLayer(tileLayer); 

    return () => {
      /* Removing layers, see: https://react-leaflet.js.org/docs/core-architecture/ */
      container.removeLayer(tileLayer)
    }

  }, [map, mapSelect]);

  return null;
}
