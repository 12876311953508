import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import {Paper, MenuList, MenuItem, ListItemIcon, ListItemText, IconButton, Typography} from '@mui/material';
import { Check } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

import { setMapSelect } from '../actions/mapSelectActions';
import { fetchMapDestinations, resetDestinationDurationMap, setFocusDestinationId } from '../actions/destinationActions';
import { setSelectedCategoryObject } from '../actions/categoryActions';
import { fetchStopTypes, setSelectedArrStopId, setSelectedDepStopId } from '../actions/stopsActions';
import { resetTravelLegs } from '../actions/travelLegsActions';


/* const theme = createTheme({
    palette: {
      background: {
        paper: '#fff',
      },
      text: {
        primary: '#173A5E',
        secondary: '#46505A',
      },
      action: {
        active: '#001E3C',
      },
      success: {
        dark: '#009688',
      },
    },
  }); */

export default function MenuDrawer({mapSelect, mapList}) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(open);
  };

  const handleClick = (mapName) => {
    setOpen(false);
    setTimeout(() => {  // izes previous function call 
      /* 
      COMPLETE RESET ON MAPCHANGE
      */
      dispatch(setSelectedDepStopId(null))
      dispatch(setSelectedArrStopId(null))

      dispatch(resetDestinationDurationMap());

      dispatch(resetTravelLegs(null))
      dispatch(setFocusDestinationId(null))
      dispatch(setSelectedCategoryObject(null))

      dispatch(setMapSelect(mapName))
      dispatch(fetchStopTypes(mapName));
      dispatch(fetchMapDestinations(mapName));
  })
  } 

  const content = <Paper sx={{ width: 320, zIndex: 100000}}>
  <MenuList dense>

  <MenuItem disabled sx={{backgroundColor: "none",}}>
    <ListItemText >
      <Typography variant="h6" component="div" sx={{ textAlign: "center" }}>Menu</Typography>
      </ListItemText>
    </MenuItem>
    <Divider />

  {!mapList ? null : <MenuItem disabled sx={{
            color: 'black',
            fontWeight: 'bold!important',
            '&.Mui-disabled': {
              color: 'black',
              fontWeight: 'bold',
              opacity: 1, // Set the opacity to 1 for the disabled state
            },
          }}
  >
    <Typography variant="h6" color="text.primary"> Select map </Typography>
    </MenuItem>}

    {!mapList ? null : 
    Object.values(mapList).map(mapItem => {
        const isSelect = mapItem.map_name === mapSelect;
        return <MenuItem key={`menu-item-map-${mapItem.map_name}`} onClick={() => handleClick(mapItem.map_name)}
    >
    {
        isSelect ? <><ListItemIcon sx={{color: "black"}}>
        <Check />
      </ListItemIcon >
      {mapItem.map_label}</> : 
      <ListItemText inset>{mapItem.map_label}</ListItemText>
    }
    </MenuItem>
    })
    }
  </MenuList>
</Paper>
/* <ThemeProvider theme={theme}> */

  return (
        <>
          <IconButton
            size="large"
            edge="start"
            aria-label="open drawer"
            sx={{ mr: 0 }}
           onClick={toggleDrawer(true)} color="inherit"><MenuIcon /></IconButton>
          <Drawer sx={{zIndex: 100000}}
            anchor="right"
            open={open}
            onClose={toggleDrawer(false)}
          >
            {content}
          </Drawer>
          </>
  );
}